import React from 'react'
import Header from '../Header/Header'

import './Layout.sass'

function Layout(props) {
  const { children, title, fullScreen = false } = props

  return (
    <>
      {!fullScreen && <Header />}
      <div className={`layout ${fullScreen ? 'fullScreen' : ''}`}>
        <div className="content-wrapper">
          {title && <h1 className='d-ib'>{title}</h1>}
          {children}
        </div>
      </div>
    </>
  );
}


export default Layout
