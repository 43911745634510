import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import locales from './locales'
const resources = locales

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    lng: 'es',
    resources,
  });

export default i18n