import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'

import {
  Dialog,
  DialogContent,
  Divider,
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  IconButton,
  Autocomplete,
  Typography,
  InputLabel
} from '@mui/material'

import {
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  WarningAmberRounded as WarningAmberRoundedIcon
} from '@mui/icons-material'

import { useTranslation } from 'react-i18next'
import './FloorMapControls.sass'


const defaultFunction = () => { }
const elem = document.documentElement


function FloorMapControls(props) {

  const {
    manualFullScreen,
    wayfindingPassphrase,
    fullScreen = false,
    expand = false,
    site = '',
    sites = [],
    building = '',
    buildings = [],
    floor = '',
    floors = [],
    space = null,
    spaces = [],
    toggleFullScreen = defaultFunction,
    setExpand = defaultFunction,
    setSite = defaultFunction,
    setBuilding = defaultFunction,
    setFloor = defaultFunction,
    setSpace = defaultFunction,
    collapseControls = false,
    setCollapseControls = defaultFunction
  } = props

  const { t } = useTranslation()

  const [fullScreenConfirm, setFullscreenConfirm] = useState(false)
  const [errorPassword, setErrorPassword] = useState('')
  const [password, setPassword] = useState('')

  const openFullscreen = () => {
    if (manualFullScreen) {
      toggleFullScreen()
      return
    };
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen()
    }
  }

  const closeFullscreen = useCallback(() => {
    if (manualFullScreen) {
      toggleFullScreen()
      return
    };
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) { /* Safari */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) { /* IE11 */
      document.msExitFullscreen()
    }
  }, [manualFullScreen, toggleFullScreen])

  const onClickFullScreen = () => {
    if (fullScreen) {
      if (wayfindingPassphrase) setFullscreenConfirm(true)
      else closeFullscreen()
    } else openFullscreen()
  }

  const onChangeSite = e => {
    setSite(e.target.value)
  }

  const onChangeSpace = (_, newValue) => {
    setSpace(newValue)
    setExpand(false)
  }

  const closeFullScreenConfirm = useCallback(close => {
    setFullscreenConfirm(false)
    setPassword('')
    setErrorPassword('')
    if (close) closeFullscreen()
  }, [closeFullscreen])

  const evalPass = useCallback(() => {
    if (password === wayfindingPassphrase) {
      closeFullScreenConfirm(true)
    } else setErrorPassword('La contraseña es incorrecta')
  }, [closeFullScreenConfirm, password, wayfindingPassphrase])

  const onChangePassword = useCallback(e => {
    setPassword(e.target.value)
    setErrorPassword('')
  }, [])

  const handleKeyPress = useCallback(e => {
    if (
      !password ||
      e.key !== 'Enter' ||
      errorPassword
    ) return
    evalPass()
  }, [errorPassword, evalPass, password])

  const fullScreenConfirmModal = useCallback(() => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={fullScreenConfirm}
        onClose={() => closeFullScreenConfirm(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-info-content detail-fullscreen">
          <div className="detail-header">
            <WarningAmberRoundedIcon />
            Autorizar salida
          </div>
          <Divider className="detail-hr" />
          <div className="detail-fullscreen-info center">
            Para salir, ingresa el código de autorización:
          </div>
          <div className='detail-footer'>
            <TextField
              fullWidth
              size="small"
              className="fullscreen-password"
              autoComplete="on"
              type="password"
              value={password}
              error={!!errorPassword}
              onKeyPress={handleKeyPress}
              onChange={onChangePassword}
            />
            <Stack spacing={2} direction="row">
              <Button
                variant="outlined"
                onClick={() => closeFullScreenConfirm(false)} >
                Cancelar
              </Button>
              <Button
                disabled={!password}
                variant="contained"
                color="primary"
                onClick={evalPass} >
                Autorizar
              </Button>
            </Stack>
          </div>
        </DialogContent>
      </Dialog>
    )
  }, [closeFullScreenConfirm, errorPassword, evalPass, fullScreenConfirm, handleKeyPress, onChangePassword, password])


  return (
    <div className={`FloorMapControls`}>
      {
        <div className={`filters-inner-wrapper ${fullScreen ? 'fullScreen' : ''}`} >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={4}
          >
            <Typography variant="h3">
              {t("What space would you like to find?")}
              {collapseControls &&
                <IconButton
                  className='expand-menu-button'
                  onClick={() => setCollapseControls(false)}
                >
                    <ExpandMoreIcon fontSize="medium" />
                </IconButton>
              }
            </Typography>
            {!collapseControls &&
              <>
                <Typography>
                  {t("Search on another site and building")}
                  <IconButton
                    className='expand-menu-button'
                    onClick={() => setExpand(!expand)}
                  >
                    {expand ?
                      <ExpandLessIcon fontSize="medium" />
                      :
                      <ExpandMoreIcon fontSize="medium" />
                    }
                  </IconButton>
                </Typography>
                {expand ?
                  <Stack
                    direction="column"
                    spacing={4}
                  >
                    <InputLabel>
                      {t('site')}
                    </InputLabel>
                    <Select
                      className="cp-select"
                      name="site"
                      value={site}
                      displayEmpty
                      onChange={onChangeSite}
                    >
                      {sites.map(s =>
                        <MenuItem
                          key={s.id}
                          value={s.id}>
                          {s.name}
                        </MenuItem>
                      )}
                    </Select>
                    <InputLabel>
                      {t('building')}
                    </InputLabel>
                    <Select
                      className="cp-select"
                      name="building"
                      value={building}
                      displayEmpty
                      onChange={e => setBuilding(e.target.value)}
                    >
                      {buildings.map(s =>
                        <MenuItem
                          key={s.id}
                          value={s.id}>
                          {s.name}
                        </MenuItem>
                      )}
                    </Select>
                    <InputLabel>
                      {t('floor')}
                    </InputLabel>
                    <Select
                      className="cp-select"
                      name="floor"
                      value={floor}
                      displayEmpty
                      onChange={e => setFloor(e.target.value)}
                    >
                      {floors.map(s =>
                        <MenuItem
                          key={s.id}
                          value={s.id}>
                          {s.name}
                        </MenuItem>
                      )}
                    </Select>
                  </Stack> :
                  <Stack
                    direction="column"
                    spacing={4}
                  >
                    <InputLabel>
                      {t('floor')}
                    </InputLabel>
                    <Select
                      className="cp-select"
                      name="floor"
                      value={floor}
                      displayEmpty
                      onChange={e => setFloor(e.target.value)}
                    >
                      {floors.map(s =>
                        <MenuItem
                          key={s.id}
                          value={s.id}>
                          {s.name}
                        </MenuItem>
                      )}
                    </Select>
                  </Stack>
                }
                <InputLabel>
                  {t('search space')}
                </InputLabel>
                <Autocomplete
                  noOptionsText={t('No Options')}
                  value={space}
                  onChange={onChangeSpace}
                  options={spaces}
                  renderInput={(params) =>
                    <TextField
                      className='search-space-input'
                      {...params}
                      placeholder={t('search space')}
                    />
                  }
                />
              </>
            }
          </Stack>
        </div>
      }
      <IconButton
        aria-label="full-screen"
        className="full-screen"
        onClick={onClickFullScreen}
      >
        {fullScreen ?
          <FullscreenExitIcon fontSize="large" /> :
          <FullscreenIcon fontSize="large" />
        }
      </IconButton>
      {fullScreenConfirmModal()}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    wayfindingPassphrase: state.profile.company.wayfinding_passphrase,
    manualFullScreen: state.explorer.manualFullScreen
  }
}

export default connect(mapStateToProps)(FloorMapControls)