const ArrowTurnRight = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 27 30">
      <path fill="#323232" d="m25.7 9-8-8a1.8 1.8 0 0 0-2.5 2.5l5 5H2.6c-1 0-1.8.7-1.8 1.7v17.5c0 1 .8 1.7 1.8 1.7s1.7-.8 1.7-1.7V11.9h16l-5 5c-.6.7-.6 1.9 0 2.5.7.7 1.8.7 2.5 0l8-8c.7-.6.7-1.8 0-2.4Z" />
    </svg>
  )
}

export default ArrowTurnRight




