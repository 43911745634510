const ArrowLeft = ({ className = '', style = {}, width = 7, height = 30 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.49625 0.781328C6.8375 1.12258 6.8375 1.67383 6.49625 2.01508L3.10125 5.41008L16.375 5.41008C16.8562 5.41008 17.25 5.80383 17.25 6.28508C17.25 6.76633 16.8562 7.16008 16.375 7.16008L3.0925 7.16008L6.4875 10.5551C6.82875 10.8963 6.82875 11.4476 6.4875 11.7888C6.14625 12.1301 5.595 12.1301 5.25375 11.7888L0.362499 6.89758C0.0212479 6.55633 0.0212479 6.00508 0.362499 5.66383L5.2625 0.781328C5.595 0.440078 6.155 0.440078 6.49625 0.781328Z" fill="#323232" />
    </svg>
  )
}

export default ArrowLeft