import { Types } from '../actionTypes'
import { clearToken } from '../../api/api'

const initialState = {
  first_name: '',
  last_name: '',
  email: '',
  card_number: '',
  tag_number: '',
  beacon: '',
  team: [],
  group: [],
  preferentialAccess: false,
  domain: 'public',
  vehicles: [],
  company: {
    max_future_days_to_reserve: 60,
    wayfinding_passphrase: ''
  },
  lng: localStorage.getItem('lng') || 'es'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_LANGUAGE:
      const lng = action.payload
      state.lng = lng
      return state
    case Types.SET_DOMAIN:
      const domain = action.payload
      state.domain = domain
      localStorage.setItem('domain', domain)
      return state
    case Types.SET_DEVISE_AUTH:
      const {
        first_name = '',
        last_name = '',
        email = '',
        card_number = '',
        tag_number = '',
        beacon = '',
        preferentialAccess = false
      } = action.payload.data

      const company = action.payload.company
      const group = action.payload.groups
      const team = action.payload.workplace_group

      return {
        ...state,
        first_name,
        last_name,
        email,
        card_number,
        tag_number,
        beacon,
        company,
        team,
        group,
        preferentialAccess,
      }
    case Types.CLEAN_DEVISE_AUTH:
      clearToken()
      return initialState
    default:
      return state
  }
}

export default reducer;