


const Wifi = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 10">
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M8.7 6.7a3.3 3.3 0 0 0-4.4 0M10.4 5a5.7 5.7 0 0 0-7.8 0M12 3A8.2 8.2 0 0 0 1 3" />
      <path fill="#000" d="M6.5 9.2a.4.4 0 1 1 0-.8.4.4 0 0 1 0 .8Z" />
    </svg>
  )
}

export default Wifi