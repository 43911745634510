const ArrowUp = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 23 35">
      <path fill="#323232" d="M.5 13c.7.7 1.8.7 2.4 0l6.7-6.6v26c0 1 .7 1.7 1.7 1.7s1.7-.8 1.7-1.7v-26l6.6 6.7a1.7 1.7 0 1 0 2.5-2.4L12.5 1A2 2 0 0 0 10 1L.5 10.6c-.6.7-.6 1.8 0 2.4Z" />
    </svg>
  )
}

export default ArrowUp