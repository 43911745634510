const profileTypes = {
  SET_DOMAIN: 'SET_DOMAIN',
  SET_VEHICLES: 'SET_VEHICLES',
  SET_DEVISE_AUTH: 'SET_DEVISE_AUTH',
  CLEAN_DEVISE_AUTH: 'CLEAN_DEVISE_AUTH'
}

const snackbarTypes = {
  SET_SNACKBAR_TOGGLE: 'SET_SNACKBAR_TOGGLE',
  SET_SNACKBAR_DATA: 'SET_SNACKBAR_DATA'
}

const backdropTypes = {
  SET_BACKDROP_TOGGLE: 'SET_BACKDROP_TOGGLE',
  SET_BACKDROP_LOADING: 'SET_BACKDROP_LOADING'
}

const utilitiesTypes = {
  SET_IS_ONLINE: 'SET_IS_ONLINE',
  SET_LANGUAGE: 'SET_LANGUAGE',
  SET_GO_HOME: 'SET_GO_HOME',
  SET_GO_LOGIN: 'SET_GO_LOGIN',
  SET_GO_INITIAL_DATA: 'SET_GO_INITIAL_DATA'
}

const explorerTypes = {
  SET_PLACE: 'SET_PLACE',
  SET_MANUAL_FULLSCREEN: 'SET_MANUAL_FULLSCREEN'
}

export const Types = {
  ...profileTypes,
  ...snackbarTypes,
  ...backdropTypes,
  ...utilitiesTypes,
  ...explorerTypes
}