const Tv = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 11 10">
      <path fill="#000" fillRule="evenodd" d="M1 .6a1 1 0 0 0-1 1v5.6c0 .5.4 1 1 1h8.8c.6 0 1-.5 1-1V1.6c0-.5-.4-1-1-1H1Zm0 .7c-.2 0-.3.2-.3.4V7c0 .2.1.4.3.4h8.8c.2 0 .3-.2.3-.4V1.7c0-.2-.1-.4-.3-.4H1Z" clipRule="evenodd" />
      <path fill="#000" d="M2 9c0-.2.3-.3.5-.3h6.1c.3 0 .5.1.5.3 0 .2-.2.4-.5.4h-6c-.3 0-.5-.2-.5-.4Z" />
    </svg>
  )
}

export default Tv