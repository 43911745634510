const ArrowRight = ({ className = '', style = {}, width = 7, height = 30 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 13">
      <path fill="#323232" d="M11.5 11.8a.9.9 0 0 1 0-1.2L15 7.2H1.6a.9.9 0 0 1-.8-1c0-.4.3-.8.8-.8H15L11.5 2A.9.9 0 1 1 12.7.8l5 4.9c.3.3.3.9 0 1.2l-5 4.9a1 1 0 0 1-1.2 0Z" />
    </svg>
  )
}

export default ArrowRight
