import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import explorer from './reducers/explorer'
import profile from './reducers/profile'
import snackbar from './reducers/snackbar'
import backdrop from './reducers/backdrop'
import utilities from './reducers/utilities'

const rootReducer = combineReducers({
  profile,
  explorer,
  snackbar,
  backdrop,
  utilities
})

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['backdrop', 'snackbar', 'utilities']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const conf = () => {
  let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)))
  let persistor = persistStore(store)
  return { store, persistor }
};

export default conf