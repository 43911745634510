import { Types } from '../actionTypes'

const initialState = {
  site: -1,
  building: -1,
  floor: -1,
  manualFullScreen: true
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_PLACE:
      const {
        site,
        building,
        floor
      } = action.payload

      return {
        ...state,
        site,
        building,
        floor
      };

    case Types.SET_MANUAL_FULLSCREEN:
      const {
        manualFullScreen
      } = action.payload

      return {
        ...state,
        manualFullScreen
      };

    default:
      return state
  }
}

export default reducer;