



const Blackboard = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 12">
      <path fill="#000" d="M5.5 10.23a.5.5 0 001 0h-1zm1-1.692a.5.5 0 00-1 0h1zm-1-7.192a.5.5 0 101 0h-1zM6.5.5a.5.5 0 00-1 0h1zm2.827 11.137a.5.5 0 00.962-.274l-.962.274zm.115-3.236a.5.5 0 10-.961.275l.961-.275zm-7.73 2.962a.5.5 0 00.961.274l-.961-.274zm1.807-2.687a.5.5 0 10-.961-.275l.961.275zm-2.173-6.83h9.308v-1H1.346v1zm9.308 0c.191 0 .346.155.346.346h1c0-.743-.603-1.346-1.346-1.346v1zm.346.346v5.5h1v-5.5h-1zm0 5.5a.346.346 0 01-.346.346v1c.743 0 1.346-.602 1.346-1.346h-1zm-.346.346H1.346v1h9.308v-1zm-9.308 0A.346.346 0 011 7.692H0c0 .744.603 1.346 1.346 1.346v-1zM1 7.692v-5.5H0v5.5h1zm0-5.5c0-.19.155-.346.346-.346v-1C.603.846 0 1.45 0 2.192h1zm5.5 8.039V8.538h-1v1.693h1zm0-8.885V.5h-1v.846h1zm3.789 10.017L9.442 8.4l-.961.275.846 2.961.962-.274zm-7.616.274l.846-2.961-.961-.275-.846 2.962.961.274z" />
    </svg>
  )
}

export default Blackboard
