import React, {useState} from 'react'
import './StepsMap.sass'

import ArrowUp from '../Svgs/ArrowUp'
import ArrowDown from '../Svgs/ArrowDown'
import ArrowLeft from '../Svgs/ArrowLeft'
import ArrowRight from '../Svgs/ArrowRight'
import ArrowDownLeft from '../Svgs/ArrowDownLeft'
import ArrowDownRight from '../Svgs/ArrowDownRight'
import ArrowTurnLeft from '../Svgs/ArrowTurnLeft'
import ArrowTurnRight from '../Svgs/ArrowTurnRight'
import ArrowUpperLeft from '../Svgs/ArrowUpperLeft'
import ArrowUpperRight from '../Svgs/ArrowUpperRight'

import Marker from '../Svgs/Marker'

import { Button } from '@mui/material'
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined'

import { t } from 'i18next'

const getDirectionIcon = ({ direction, end }) => {
  if (end) return <Marker />
  if (direction === "UP") return <ArrowUp />  // "Arriba"
  if (direction === "DOWN") return <ArrowDown />  //" Abajo"
  if (direction === "LEFT") return <ArrowLeft />  // "Izquierda"
  if (direction === "RIGHT") return <ArrowRight />  // "Derecha"
  if (direction === "UPPER_LEFT") return <ArrowUpperLeft />  // "Arriba a la izquierda"
  if (direction === "UPPER_RIGHT") return <ArrowUpperRight />  // "Arriba a la derecha"
  if (direction === "DOWN_LEFT") return <ArrowDownLeft />  // "Abajo a la izquierda"
  if (direction === "DOWN_RIGHT") return <ArrowDownRight />  // "Abajo a la derecha"
  if (direction === "TURN_UP") return <ArrowUp />  // "Girar hacia arriba"
  if (direction === "TURN_DOWN") return <ArrowDown />  // Girar hacia abajo"
  if (direction === "TURN_LEFT") return <ArrowTurnLeft />  // "Girar a la izquierda"
  if (direction === "TURN_RIGHT") return <ArrowTurnRight />  // "Girar a la derecha"
  return <Marker />
}

const synth = window.speechSynthesis

function StepsMap(props) {
  const {
    steps = [],
  } = props

  const [isSpeaking, setIsSpeaking] = useState(false)

  const onClickPath = step => {
    const utterThis = new SpeechSynthesisUtterance(step.description)
    utterThis.lang = 'es-US'
    synth.cancel()
    synth.speak(utterThis)
    if (props.onClickPath) props.onClickPath(step)
  }

  const speakAllSteps = () => {
    setIsSpeaking(true)
    synth.cancel()

    steps.forEach((step, index) => {
      setTimeout(() => {
        onClickPath(step)

        if (index === steps.length - 1) {
          setIsSpeaking(false)
        }
      }, index * 2000)
    })
  }

  return (
    <div className='StepsMap'>
      <div className='listen-instructions'>
        <Button
          onClick={speakAllSteps} 
          disabled={isSpeaking}
          variant='text'
        >
          <VolumeUpOutlinedIcon />
          <p>{t("Listen to instructions")}</p>
        </Button>
      </div>
      {steps.map((step, index) =>
        <div
          className={`step ${step.active ? 'active' : ''}`}
          onClick={() => onClickPath(step)}
          key={index}
          >
          {getDirectionIcon(step)}
          <p>
            {step.description}
          </p>
        </div>
      )}
    </div>
  )
}

export default StepsMap