import { Types } from "../actionTypes"
import { api, setToken } from '../../api/api'

let global_dispatch = {}

const logout = () => {
  global_dispatch({ type: Types.CLEAN_DEVISE_AUTH })
  global_dispatch({ type: Types.SET_GO_LOGIN })
}

const setAuth = (dispatch, data) => {
  dispatch({
    type: Types.SET_DEVISE_AUTH,
    payload: data
  })

  dispatch({ type: Types.SET_GO_HOME })
}

const deviseAuth = (payload, baseURL, domain) => {
  return function (dispatch) {
    dispatch({ type: Types.SET_DOMAIN, payload: domain })
    return api.post('/auth/sign_in', payload, { baseURL })
      .then(({ data }) => {
        const { api_key } = data.tokens[0]
        if (!api_key) throw new Error('password is incorrect')
        setAuth(dispatch, data)
        setToken(data.tokens[0].api_key)
      })
  }
}

const loadLogout = () => {
  return function (dispatch) {
    global_dispatch = dispatch
    logout()
  }
}

const setLoading = loading => {
  return function (dispatch) {
    dispatch({
      type: Types.SET_BACKDROP_LOADING,
      payload: { loading }
    })
  }
}

const setSnackbar = payload => {
  return function (dispatch) {
    dispatch({
      type: Types.SET_SNACKBAR_DATA,
      payload
    })
  }
}

export {
  setLoading,
  setSnackbar,
  loadLogout,
  deviseAuth
}