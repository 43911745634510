const MAX_401_ATTEMPTS = 5
const REGEX_HTTP_URL = /\(https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)('')?/g
const REGEX_ENDLINE = /\n/g
// eslint-disable-next-line no-useless-escape
const REGEX_EMAIL = /[a-zA-Z0-9\-]+[\._]?[a-zA-Z0-9]+[@][\w\-]+[.]\w{2,3}/


const DEFAULT_FLOOR_IMAGE = { url: 'static_map.png', size: [] }
const IMAGE_BOUNDS = [[0, 0], [1000, 1000]]
const CENTER = [0, 0]
const AMENITIES = [
  'AIR_CONDITIONER',
  'BOARD',
  'PLUG',
  'PROJECTOR',
  'VIDECONFERENCE',
  'WIFI',
  'TV'
]

export {
  MAX_401_ATTEMPTS,
  REGEX_HTTP_URL,
  REGEX_ENDLINE,
  REGEX_EMAIL,
  DEFAULT_FLOOR_IMAGE,
  IMAGE_BOUNDS,
  AMENITIES,
  CENTER
}