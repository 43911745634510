import
React,
{
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react'

import {
  IconButton,
  InputAdornment,
  Button,
  TextField,
  Stack,
  CircularProgress,
  useMediaQuery
} from '@mui/material'

import {
  Visibility,
  VisibilityOff
} from '@mui/icons-material'

import { useTheme } from '@mui/material/styles'
import { connect } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Logo from '../../common/components/Svgs/Logo'
import { setLoading, deviseAuth } from '../../state/actions'
import { api, getBaseUrl, clearToken } from '../../api/api'
import { REGEX_EMAIL } from '../../variables'
import Isotype from '../../common/components/Svgs/Isotype'

import './Login.sass'

function Login(props) {
  const {
    goHome,
    loading,
    setLoading,
    deviseAuth
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  let navigate = useNavigate()
  const ref = useRef()
  let location = useLocation()
  let from = location.state?.from?.pathname || "/";
  const handleNavigate = useCallback(
    () => navigate(from, { replace: true }),
    [from, navigate]
  )

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [baseUrl, setBaseUrl] = useState('')
  const [domain, setDomain] = useState('')
  const [showPasswordField, setShowPasswordField] = useState(false)


  useEffect(() => {
    if (!ref.current) {
      ref.current = 1
      return
    }

    if (goHome) handleNavigate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goHome, handleNavigate])

  const authType = useCallback(() => {
    return api.post('/auth/type', { email })
      .then(({ data }) => {
        const { domains = [] } = data
        const domain = domains[0]
        const baseUrl = getBaseUrl(domain)

        classicAuth(data, baseUrl, domain)
      })
  }, [email])


  const evalEmail = useCallback(() => {
    let error = ''

    if (
      email &&
      !REGEX_EMAIL.test(email)
    ) {
      error = t('invalid email')
    }

    setEmailError(error)
    return error
  }, [email, t])

  const handleTypeAuth = useCallback(() => {
    const emailError = evalEmail()
    if (emailError) return
    setLoading(true)
    authType({ email })
      .finally(() => setLoading(false))
  }, [authType, email, evalEmail, setLoading])


  const handleEmail = useCallback(e => {
    const email = e.target.value
    setPassword('')
    setEmailError('')
    setPasswordError('')
    setShowPasswordField(false)
    clearToken()
    setEmail(email)
  }, [])

  const handlePassword = e => {
    setPasswordError('')
    setPassword(e.target.value)
  }

  const isDisabled = useCallback(
    () => loading || !(email && password),
    [email, loading, password]
  )

  const isDisabledNext = useCallback(
    () => loading || !!emailError || !email,
    [email, emailError, loading]
  )

  const classicAuth = (data, baseUrl, domain) => {
    setDomain(domain)
    setBaseUrl(baseUrl)
    setShowPasswordField(true)
  }

  const loginCatch = useCallback(e => {
    if (e?.response?.status === 401) {
      setPasswordError(t('password is incorrect'))
    } else if (e?.response?.message) {
      setPasswordError(e?.response?.message)
    } else if (e?.message) {
      setPasswordError(t(e?.message))
    } else setPasswordError(t('unknown error'))
  }, [t])

  const handleClassicAuth = useCallback(() => {
    setLoading(true)

    deviseAuth({ email, password }, baseUrl, domain)
      .catch(loginCatch)
      .finally(() => setLoading(false))

  }, [baseUrl, deviseAuth, domain, email, password, setLoading, loginCatch])

  const emailProps = useMemo(() => {
    return ({
      id: 'email',
      name: 'email',
      className: 'cp-text-field',
      fullWidth: true,
      size: 'small',
      autoComplete: 'on',
      variant: 'standard',
      placeholder: t('enter your email'),
      label: t('email'),
      type: 'email'
    })
  }, [t])

  const passwordProps = useMemo(() => {
    return ({
      id: 'password',
      name: 'password',
      className: 'cp-text-field',
      fullWidth: true,
      size: 'small',
      autoFocus: true,
      variant: 'standard',
      placeholder: t('enter your password'),
      label: t('password')
    })
  }, [t])

  const handleKeyPress = useCallback(event => {
    if (event.key !== 'Enter') return
    if (isDisabled()) return
    handleClassicAuth()
  }, [handleClassicAuth, isDisabled])

  const handleEmailKeyPress = useCallback(event => {
    if (
      !email ||
      event.key !== 'Enter' ||
      loading ||
      showPasswordField
    ) return

    handleTypeAuth()
  }, [email, handleTypeAuth, loading, showPasswordField])

  const getButtons = useCallback(() => {
    if (showPasswordField) return (
      <Button
        className="cp-button"
        fullWidth
        size="large"
        variant="contained"
        disabled={isDisabled()}
        onClick={handleClassicAuth}>
        {loading ? (
          <CircularProgress
            size={24}
          />
        ) : t("login")
        }
      </Button>
    )

    return (
      <Button
        className="cp-button next"
        fullWidth
        size="large"
        variant="contained"
        disabled={isDisabledNext()}
        onClick={handleTypeAuth}>
        {loading ? (
          <CircularProgress
            size={24}
          />
        ) : t("next")
        }
      </Button>
    )
  }, [handleClassicAuth, handleTypeAuth, isDisabled, isDisabledNext, loading, showPasswordField, t])


  const loginForm = useCallback(() => {
    return (
      <div className="login-inner">
        <Isotype className="logo" />
        <h1 className='welcome-message'>
          { t('Access to Wayfinding') }
        </h1>
        <Stack spacing={2}>
          {isLarge &&
            <label className="cp-label" htmlFor='email'>
              {t('email')}
            </label>
          }
          <TextField
            {...emailProps}
            value={email}
            error={!!emailError}
            helperText={emailError}
            onKeyPress={handleEmailKeyPress}
            onChange={handleEmail}
          />
          {showPasswordField && <>
            {isLarge && <span className="cp-span">
              <label
                className="cp-label"
                htmlFor='password'>
                {t('password')}
              </label>
            </span>}
            <TextField
              {...passwordProps}
              type={showPassword ? 'text' : 'password'}
              value={password}
              error={!!passwordError}
              helperText={passwordError}
              onChange={handlePassword}
              onKeyPress={handleKeyPress}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
          </>}
          {getButtons()}
          <a
            className="privacy"
            href="https://www.parso.co/politica-de-privacidad-corporate-experience"
            target="_blank"
            rel="noreferrer">
            {t('privacy policy')}
          </a>
        </Stack>
      </div>
    )
  }, [isLarge, t, emailProps, email, emailError, handleEmailKeyPress, handleEmail, showPasswordField, passwordProps, showPassword, password, passwordError, handleKeyPress, getButtons])

  return (
    <div className="Login">
      <Logo className="full-logo" />
      {loginForm()}
    </div>
  )
}

const mapDispatchToProps = {
  setLoading,
  deviseAuth
}

const mapStateToProps = state => {
  return {
    loading: state.backdrop.loading,
    goHome: state.utilities.goHome
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)