const VideoConference = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 10">
      <path fill="#000" d="M17.6 0H2C1.4 0 .8.5.8 1.2v7.6c0 .7.6 1.2 1.2 1.2h15.6c.7 0 1.2-.5 1.2-1.2V1.2c0-.7-.5-1.2-1.2-1.2ZM7.4 9.2v-.4c0-1.3 1.1-2.4 2.4-2.4 1.3 0 2.4 1 2.4 2.4v.4H7.4ZM18 8.8c0 .2-.2.4-.4.4H13v-.4c0-1.8-1.4-3.2-3.2-3.2a3.2 3.2 0 0 0-3.2 3.2v.4H2a.4.4 0 0 1-.4-.4V1.2c0-.2.2-.4.4-.4h15.6c.2 0 .4.2.4.4v7.6Zm-6.4-5c0-1-.8-1.8-1.8-1.8S8 2.8 8 3.8s.8 1.8 1.8 1.8 1.8-.8 1.8-1.8Zm-1.8 1a1 1 0 0 1-1-1c0-.6.5-1 1-1 .6 0 1 .4 1 1s-.4 1-1 1Z" />
    </svg>

  )
}

export default VideoConference