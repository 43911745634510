const ArrowDownRight = ({ className = '', style = {}, width = 7, height = 30 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path fill="#323232" d="M4.9 12.5c0-.5.4-.8.8-.8h5L1 2a.9.9 0 1 1 1.3-1l9.6 9.5v-5c0-.4.4-.8.8-.8.5 0 1 .4 1 .8v7c0 .5-.5 1-1 1h-7a.9.9 0 0 1-.8-1Z" />
    </svg>
  )
}

export default ArrowDownRight
