const ArrowTurnLeft = ({ className = '', style = {}, width = 7, height = 30 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.731203 9.41212L8.73844 1.40489C9.42028 0.723047 10.5392 0.723047 11.221 1.40489C11.9029 2.08672 11.9029 3.20564 11.221 3.88748L6.18592 8.90511L23.9662 8.90511C24.9277 8.90511 25.7145 9.69185 25.7145 10.6534L25.7145 28.1365C25.7145 29.098 24.9277 29.8848 23.9662 29.8848C23.0046 29.8848 22.2179 29.098 22.2179 28.1365L22.2179 12.4017L6.18592 12.4017L11.221 17.4194C11.9029 18.1012 11.9029 19.2201 11.221 19.9019C10.5392 20.5838 9.42028 20.5838 8.73844 19.9019L0.731203 11.8947C0.0493644 11.2129 0.0493643 10.094 0.731203 9.41212Z" fill="#323232" />
    </svg>
  )
}

export default ArrowTurnLeft