import React from 'react'
import { Routes, Route, BrowserRouter, useLocation, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'

import GlobalHandler from './common/components/GlobalHandler/GlobalHandler'
import Login from './pages/LoginPage/Login'
import Explore from './pages/ExplorePage/Explore'
import NotFound from './pages/NotFoundPage/NotFound'
import { clearToken } from './api/api'

const GH = GlobalHandler

function Auth({ children, profile }) {
  let location = useLocation()
  if (!profile.email) {
    clearToken()
    return (
      <Navigate
        to="/login"
        state={{ from: location }}
      />
    )
  }
  return children
}

const mapStateToProps = state => {
  return {
    profile: state.profile
  }
}

const RequireAuth = connect(mapStateToProps)(Auth)

function RoutingSystem() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<GH><Login /></GH>} />
        <Route path="/" element={<RequireAuth><GH><Explore /></GH></RequireAuth>} />
        <Route path='*' exact={true} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default RoutingSystem
