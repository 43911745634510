import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { PersistGate } from 'redux-persist/integration/react'
import './fonts/AvenirLTStd-Medium.otf'
import 'normalize.css'
import './index.sass'
import './i18n'
import configureStore from './state/store'
import RoutingSystem from './routes'
import theme from './theme'
import GlobalSnackbar from './common/components/GlobalSnackbar/GlobalSnackbar'
import GlobalBackdrop from './common/components/GlobalBackdrop/GlobalBackdrop'
import NetworkDetector from './common/components/NetworkDetector/NetworkDetector.js'

(function () {
  if (process.env.REACT_APP_ONLY_HTTPS) {
    const { href, protocol } = window.location
    if (protocol !== 'https:') {
      const new_url = href.replace(/^http:\/\//i, 'https://')
      window.location.replace(new_url)
    }
  }
})()


const { store, persistor } = configureStore()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RoutingSystem />
          <NetworkDetector />
          <GlobalSnackbar />
          <GlobalBackdrop />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
)
