const VideoBean = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}

      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 10">
      <path fill="#000" d="M12.5 0H1.786C.8 0 0 .8 0 1.786V6.07c0 .985.801 1.786 1.786 1.786h.357v1.429H1.07a.357.357 0 0 0 0 .714h2.86a.357.357 0 0 0 0-.714H2.857V7.857h8.572v1.429h-1.072a.357.357 0 0 0 0 .714h2.857a.357.357 0 0 0 0-.714h-1.071V7.857h.357c.984 0 1.786-.8 1.786-1.786V1.786C14.286.8 13.484 0 12.5 0Zm1.071 6.071c0 .591-.48 1.072-1.071 1.072H1.786c-.591 0-1.072-.48-1.072-1.072V1.786c0-.591.481-1.072 1.072-1.072H12.5c.59 0 1.071.481 1.071 1.072V6.07Z" />
      <path fill="#000" d="M10.357 1.428a2.503 2.503 0 0 0-2.5 2.5c0 1.379 1.122 2.5 2.5 2.5 1.379 0 2.5-1.121 2.5-2.5 0-1.378-1.121-2.5-2.5-2.5Zm0 4.286a1.787 1.787 0 1 1 1.786-1.786c0 .985-.801 1.786-1.786 1.786ZM6.071 2.143H2.5a.357.357 0 0 0 0 .714h3.57a.357.357 0 0 0 0-.714Zm0 2.857H2.5a.357.357 0 0 0 0 .714h3.57a.357.357 0 0 0 0-.714Z" />
    </svg>
  )
}

export default VideoBean