import { Types } from '../actionTypes'

const initialState = {
  open: false,
  message: 'This is a success message!',
  severity: 'success',
  autoHideDuration: 6000,
  vertical: 'bottom',
  horizontal: 'center'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_SNACKBAR_TOGGLE:
      return {
        ...state,
        open: !state.open
      }
    case Types.SET_SNACKBAR_DATA:
      const {
        open = false,
        message = '',
        severity = 'success',
        autoHideDuration = 6000,
        vertical = 'top',
        horizontal = 'center'
      } = action.payload

      return {
        ...state,
        open,
        message,
        severity,
        autoHideDuration,
        vertical,
        horizontal
      }
    default:
      return state;
  }
}

export default reducer;