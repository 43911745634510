import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'

import {
  Stack,
  Dialog,
  DialogContent,
  SwipeableDrawer,
  TextField,
  Divider,
  Button,
  IconButton,
  Autocomplete,
  Paper
} from '@mui/material'

import {
  ArrowForward as ArrowForwardIcon,
  Close as CloseIcon,
  East as EastIcon,
  Room as RoomIcon
} from '@mui/icons-material'

import Layout from '../../common/components/Layout/Layout'
import FloorMap from '../../common/components/FloorMap/FloorMap'
import FloorMapControls from '../../common/components/FloorMapControls/FloorMapControls'
import StepsMap from '../../common/components/StepsMap/StepsMap'
import { api } from '../../api/api'


import {
  setLog,
  types as errorTypes
} from '../../common/utilities/LogError'

import { capitalizeFirstLetter } from '../../common/utilities/utilities'

import Blackboard from '../../common/components/Svgs/Blackboard'
import Wifi from '../../common/components/Svgs/Wifi'
import Tv from '../../common/components/Svgs/Tv'
import VideoConference from '../../common/components/Svgs/VideoConference'
import VideoBean from '../../common/components/Svgs/VideoBean'
import AirCondition from '../../common/components/Svgs/AirCondition'

import { Types } from '../../state/actionTypes'

import './Explore.sass'
import { t } from 'i18next'

const initialFloorImage = {
  url: '',
  size: []
}

const initialCenter = { x: 0, y: 0 }
const defaultMarkerPhoto = { src: '', url: '' }

function Explore(props) {

  const {
    defaultSite,
    defaultBuilding,
    defaultfloor,
    dispatch,
    manualFullScreen
  } = props

  const [fullScreen, setFullScreen] = useState(true)
  const [expand, setExpand] = useState(false)
  const [site, setSite] = useState('')
  const [sites, setSites] = useState([])
  const [building, setBuilding] = useState('')
  const [buildings, setBuildings] = useState([])
  const [floor, setFloor] = useState('')
  const [floors, setFloors] = useState([])
  const [space, setSpace] = useState(null)
  const [spaces, setSpaces] = useState([])

  const [open, setOpen] = useState(false)
  const [openDetailMap, setOpenDetailMap] = useState(false)
  const [openHowToFGet, setOpenHowToFGet] = useState(false)
  const [openExistAlert, setOpenExistAlert] = useState(false)

  const [path, setPath] = useState([])
  const [markers, setMarkers] = useState([])
  const [closePopup, setClosePopup] = useState(0)
  const [from, setFrom] = useState(null)
  const [errorFrom, setErrorFrom] = useState(false)
  const [pointSelect, setPointSelect] = useState({})
  const [firstCharge, setFirstCharge] = useState(true)

  const [centerMap, setCenterMap] = useState(initialCenter)
  const [centerGlobalMap, setCenterGlobalMap] = useState(initialCenter)

  const [floorImage, setFloorImage] = useState(initialFloorImage)
  const [floorMap, setFloorMap] = useState({})

  const [markerPhoto, setMarkerPhoto] = useState(defaultMarkerPhoto)
  const [origins, setOrigins] = useState([])
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [collapseControls, setCollapseControls] = useState(false)
  const [showInstructions, setShowInstructions] = useState(true)

  const setLoading = useCallback(loading => {
    dispatch({
      type: Types.SET_BACKDROP_LOADING,
      payload: { loading }
    })
  }, [dispatch])

  const onfullscreenchange = useCallback(e => {
    toggleFullScreen()
  }, [])

  const defaultSuccess = useCallback(r => {
    setLoading(false)
    return r
  }, [setLoading])

  const defaultCatch = useCallback(error => {
    setLoading(false)

    if (error.response) {
      setLog({
        title: error.response.status,
        type: errorTypes.API_ERROR,
        message: error.response.data.errors,
        api_url: error.response.config.url,
        api_method: error.response.config.method,
        api_params: error.response.config.data
      })
    } else setLog({ type: errorTypes.API_ERROR, message: error.message })

  }, [setLoading])

  const sortItems = (items = []) => {
    return items.sort((a, b) => {
      const _a = a.name.toLowerCase()
      const _b = b.name.toLowerCase()

      if (_a < _b) return -1
      else if (_a > _b) return 1
      return 0
    })
  }

  const responseError = useCallback(url => {
    dispatch({
      type: Types.SET_SNACKBAR_DATA,
      payload: {
        open: true,
        title: "Response Error",
        message: url,
        severity: 'error',
        autoHideDuration: 6000
      }
    })
  }, [dispatch])

  const setDefaultPlace = useCallback(place => {
    dispatch({
      type: Types.SET_PLACE,
      payload: place
    })
  }, [dispatch])

  const fetchFloorsMarkers = useCallback(floor => {
    setLoading(true)
    const url = `/workplace/floor_map/markers/${floor}`
    api.get(url)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data) responseError(url)
        const _markers = sortItems(data.markers)
        setMarkers(_markers.map(m => ({ ...m, popup: true })))
        setSpaces(_markers.map(m => ({ ...m, label: m.name })))
      })
      .catch(defaultCatch)
  }, [defaultCatch, defaultSuccess, responseError, setLoading])


  const fetchFloorsImage = useCallback(params => {
    const { id, width, height } = params
    setLoading(true)
    const url = `/workplace/floor_map/serve/${id}`;
    api.get(url, { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data) responseError(url)
        const blob = URL.createObjectURL(data)
        setFloorImage({ url: blob, size: [width, height] })
        fetchFloorsMarkers(id)
      })
      .catch(defaultCatch)
  }, [defaultCatch, defaultSuccess, fetchFloorsMarkers, responseError, setLoading])

  const setExploreFloor = useCallback(floor => {
    const _floor = floors.find(f => f.id === floor)
    const { floor_map } = _floor
    setSpace(null)
    setFloor(floor)
    setClosePopup(prev => prev + 1)
    if (!floor_map || !floor_map.id) {

      dispatch({
        type: Types.SET_SNACKBAR_DATA,
        payload: {
          open: true,
          message: "El plano de este piso no está disponible",
          severity: 'error',
          autoHideDuration: 6000
        }
      })
      return;
    }
    setFloorMap(floor_map)
    fetchFloorsImage(floor_map)
    setDefaultPlace({ site, building, floor })
  }, [building, dispatch, fetchFloorsImage, floors, setDefaultPlace, site])

  const getDefaultPlace = useCallback((places, defaultPlace) => {
    if (places.some(f => f.id === defaultPlace)) return defaultPlace;
    return places[0].id;
  }, [])

  const fetchFloors = useCallback(building => {
    setLoading(true)
    const url = `/workplace/floors/${building}`;
    api.get(url)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) {
          responseError(url)
          return
        }
        const floors = sortItems(data.floors)
        if (floors.length === 0) return
        setFloors(floors)
        if (firstCharge) {
          const floor = getDefaultPlace(floors, defaultfloor);
          setFloor(floor)
        }
      })
      .catch(defaultCatch)
  }, [defaultCatch, defaultSuccess, defaultfloor, firstCharge, getDefaultPlace, responseError, setLoading])

  useEffect(() => {
    if (firstCharge && floor) {
      setFirstCharge(false)
      setExploreFloor(floor)
    }
  }, [firstCharge, floor, setExploreFloor])

  const fetchBuilding = useCallback(site => {
    setLoading(true)
    const url = `/workplace/buildings/${site}`
    api.get(url)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) {
          responseError(url)
          return
        }
        const buildings = sortItems(data.buildings)
        if (buildings.length === 0) return;
        setBuildings(buildings)
        if (firstCharge) {
          const building = getDefaultPlace(buildings, defaultBuilding)
          fetchFloors(building)
          setBuilding(building)
        }
      })
      .catch(defaultCatch)
  }, [defaultBuilding, defaultCatch, defaultSuccess, fetchFloors, firstCharge, getDefaultPlace, responseError, setLoading])

  const fetchSites = useCallback(() => {
    setLoading(true)
    const url = '/workplace/sites'
    api.get(url)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) {
          responseError(url)
          return
        }
        const sites = sortItems(data.sites)
        if (sites.length === 0) return
        setSites(sites)
        if (firstCharge) {
          const site = getDefaultPlace(sites, defaultSite);
          fetchBuilding(site)
          setSite(site)
        }
      })
      .catch(defaultCatch)
  }, [defaultCatch, defaultSite, defaultSuccess, fetchBuilding, firstCharge, getDefaultPlace, responseError, setLoading])

  const cleanSteps = (steps = []) => {
    const _steps = steps.sort((a, b) => (a.step_index - b.step_index))
    return _steps.map(
      (s, index) => ({
        end: (steps.length === (index + 1)),
        type: 'path',
        ...s
      })
    )
  }

  const fetchOrigins = point => {
    setLoading(true)
    const params = {
      to_type: point.type,
      to_id: point.id
    }

    const url = `/wayfinding/paths/search/${floorMap.id}.json`
    api.post(url, params)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) {
          responseError(url)
          return
        }
        const _origins = sortItems(data.froms)
        setOrigins(_origins.map(m => ({ ...m, label: m.name })))
        setOpenHowToFGet(true)
      })
      .catch(defaultCatch)
  }

  const clearType = (from_type = '') => {
    const _from = from_type.split('::')[1]
    if (_from) return _from.toLowerCase()
    return from_type
  }

  const fetchPath = useCallback(() => {
    setLoading(true)

    let {
      from_type,
      from_id
    } = from

    from_type = clearType(from_type)

    const params = {
      from_type,
      from_id,
      to_type: pointSelect.type,
      to_id: pointSelect.id
    }

    const url = `/wayfinding/paths/search/${floorMap.id}.json`
    api.post(url, params)
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) {
          responseError(url)
          return
        }
        if (data.paths.length) {
          setPath(cleanSteps(data.paths[0].steps))
          setOpenDetailMap(true)
        } else {
          setErrorFrom(true)
        }
      })
      .catch(defaultCatch)
  }, [defaultCatch, defaultSuccess, floorMap.id, from, pointSelect.id, pointSelect.type, responseError, setLoading])

  useEffect(() => {
    fetchSites()
  }, [fetchSites])


  useEffect(() => {
    if (origins.length === 1) setExploreFrom(origins[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origins])


  useEffect(() => {
    if (manualFullScreen) return;
    document.addEventListener("fullscreenchange", onfullscreenchange)
    return () => document.removeEventListener("fullscreenchange", onfullscreenchange)
  }, [manualFullScreen, onfullscreenchange])

  const toggleFullScreen = () => {
    setFullScreen(prev => !prev)
  }

  const clickAction = (action, point) => {
    setPointSelect(point)
    if (action === 'howToGet') fetchOrigins(point)
    else if (action === 'detail') setOpen(true)
  }

  const setExploreSite = useCallback(site => {
    setSite(site)
    fetchBuilding(site)
    setBuilding('')
    setFloor('')
    setSpace(null)
    setClosePopup(prev => prev + 1)
  }, [fetchBuilding])

  const setExploreBuilding = useCallback(building => {
    setBuilding(building)
    fetchFloors(building)
    setFloor('')
    setSpace(null)
    setClosePopup(prev => prev + 1)
  }, [fetchFloors])

  const setExploreSpace = useCallback(space => {
    if (!space) return ''
    markers.forEach(m => {
      if (m.selected) m.selected = false
      if (
        m.id === space.id &&
        m.type === space.type
      ) m.active = true
      else m.active = false
    })
    setMarkers([...markers])
    setSpace(space)
    setCenterGlobalMap(space)
  }, [markers])

  const setExploreFrom = useCallback(from => {
    setFrom(from)
    setErrorFrom(false)
  }, [])

  const fetchMarkerPhoto = useCallback(marker => {
    const { type } = marker;
    let photo = '';

    if (
      type === 'desk' &&
      marker.desk_photos &&
      marker.desk_photos.length
    ) {
      photo = marker.desk_photos[marker.desk_photos.length - 1]
    } else if (
      type === 'room' &&
      marker.room_photos &&
      marker.room_photos.length
    ) {
      photo = marker.room_photos[marker.room_photos.length - 1]
    } else {
      setMarkerPhoto(defaultMarkerPhoto)
      return;
    }

    const url = `/workplace/${type}_photo/${photo.id}`;
    if (url === markerPhoto.url) return;

    setLoading(true)
    api.get(url, { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data) responseError(url)
        const blob = URL.createObjectURL(data)
        setMarkerPhoto({ src: blob, url })
      })
      .catch(defaultCatch)
  }, [defaultCatch, defaultSuccess, markerPhoto, responseError, setLoading])

  const onClickMarker = (_, marker) => {
    if (space) setSpace(null)
    markers.forEach(m => {
      if (m.active) m.active = false
      if (
        m.id === marker.id &&
        m.type === marker.type
      ) m.selected = true
      else m.selected = false
    })
    setMarkers([...markers])
    setSelectedMarker(marker)
    fetchMarkerPhoto(marker)
    setExpand(false)
    setCenterGlobalMap(marker)
    setCollapseControls(true)
  }

  const getPlace = useCallback(() => {
    if (!site || !building || !buildings) return ''
    const _site = sites.find(s => s.id === site) || {}
    const _building = buildings.find(b => b.id === building) || {}
    return `${_site.name}, ${_building.name}`
  }, [building, buildings, site, sites])

  const getFloorName = useCallback(() => {
    if (!floor || !floors) return ''
    const _floor = floors.find(f => f.id === floor) || {}
    return `${_floor.name}`
  }, [floor, floors])

  const renderControls = useCallback(() => {
    return (
      <FloorMapControls
        fullScreen={fullScreen}
        expand={expand}
        toggleFullScreen={toggleFullScreen}
        setExpand={setExpand}
        setOpen={setOpen}
        sites={sites}
        site={site}
        buildings={buildings}
        building={building}
        floors={floors}
        floor={floor}
        spaces={spaces}
        space={space}
        setSite={setExploreSite}
        setBuilding={setExploreBuilding}
        setFloor={setExploreFloor}
        setSpace={setExploreSpace}
        collapseControls={collapseControls}
        setCollapseControls={setCollapseControls}
      />
    )
  }, [building, buildings, expand, floor, floors, fullScreen, setExploreBuilding, setExploreFloor, setExploreSite, setExploreSpace, site, sites, space, spaces, collapseControls, setCollapseControls])

  const exits = useCallback(() => {
    setOpenExistAlert(false)
    setOpenDetailMap(false)
    setOpenHowToFGet(false)
    setErrorFrom(false)
    setFrom(null)
    selectedMarker.selected = false
    setSelectedMarker(null)
    setCollapseControls(false)
  }, [setOpenExistAlert, setOpenDetailMap, setOpenHowToFGet, setErrorFrom, selectedMarker, setCollapseControls ])

  const exitAlert = useCallback(() => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openExistAlert}
        onClose={() => setOpenExistAlert(false)}
        aria-labelledby="responsive-dialog-title">
        <DialogContent className="dialog-info-content">
          <div className="detail-header">
            Salir de ruta
          </div>
          <Divider className="detail-hr" />
          <div className="detail-remove-info center">
            ¿Quieres salir de las indicaciones de la ruta?
          </div>
          <Stack spacing={2} direction="row" className='detail-footer'>
            <Button
              variant="outlined"
              onClick={() => setOpenExistAlert(false)} >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={exits} >
              Sí, salir
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [openExistAlert, exits])

  const getAmenities = useCallback(pointSelect => {
    const { amenities = [] } = pointSelect
    if (amenities.length === 0) return ''
    return <div>
      {amenities.includes(0) && <div className='amenitie'><AirCondition />Aire Acondicionado</div>}
      {amenities.includes(1) && <div className='amenitie'><Blackboard />Pizarra</div>}
      {amenities.includes(2) && <div className='amenitie'><VideoBean />Proyector</div>}
      {amenities.includes(3) && <div className='amenitie'><VideoConference />Videoconferencia</div>}
      {amenities.includes(4) && <div className='amenitie'><Wifi />Wifi</div>}
      {amenities.includes(5) && <div className='amenitie'><Tv />Pantalla</div>}
    </div>
  }, [])

  const detailRoom = useCallback(() => {
    return (
      <Dialog
        className="detail-room"
        fullWidth={true}
        maxWidth='md'
        open={open}
        onClose={(e) => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
        disableEscapeKeyDown
        disableBackdropClick
        >
        <DialogContent>
          <Stack
            direction="column"
            spacing={2}
          >
            <h2 className='title'>
              {capitalizeFirstLetter(pointSelect.name)}
              <IconButton
                className="close-button"
                aria-label="close"
                onClick={() => {
                  setCollapseControls(false)
                  selectedMarker.selected = false
                  setSelectedMarker(null)
                  setOpen(false)
                }} >
                <CloseIcon />
              </IconButton>
            </h2>
            <Divider className='divider' />
            <Stack
              direction="row"
              alignItems={getAmenities(pointSelect) ? "flex-start" : "center"}
              spacing={2}
            >
              <Stack
                className='left-container'
                direction='column'
                spacing={2}
                justifyContent='center'
              >
                <p>{t("Capacity: ")} <strong>{` ${pointSelect.capacity || 0} ${pointSelect.capacity === 1 ? t("person") : t("people")}`}</strong></p>
                {
                  getAmenities(pointSelect) &&
                    <div className="amenities">
                      <label className="amenities-label">
                        {t("Space's amenities: ")}
                      </label>
                      {getAmenities(pointSelect)}
                    </div>
                }
              </Stack>
              {
                markerPhoto && 
                  markerPhoto.src &&
                    <img
                      src={markerPhoto.src}
                      alt='office'
                      width='100%'
                      height='100%'
                    />
              }
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    )
  }, [getAmenities, markerPhoto, open, pointSelect, selectedMarker, setSelectedMarker, setCollapseControls])

  const onClickPath = useCallback(step => {
    path.forEach(_step => {
      if (
        _step.step_index === step.step_index &&
        _step.type === step.type
      ) _step.active = true
      else _step.active = false
    })
    setPath([...path])
    setCenterMap(step)
  }, [path])

  const detailMap = useCallback(() => {
    return (
      <Dialog
        className="detail-map"
        fullScreen={true}
        open={openDetailMap}
        onClose={() => setOpenExistAlert(true)}
      >
        <DialogContent className="dialog-detail-content">
          <h3 className='dialog-title'>
            {`${getPlace()}, ${ getFloorName()}`}
            <IconButton
              className="close-button"
              aria-label="close"
              onClick={() => setOpenExistAlert(true)} >
              <CloseIcon />
            </IconButton>
          </h3>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            className="static-detail-map-filters"
          >
            <RoomIcon className='route-icons' />
            <Stack
              spacing={0.3}
              direction="column"
              alignItems="start"
              justifyContent="flex-start"
            >
              <p className='route'>{`${t('Route')}:`}</p>
              <Stack
                spacing={3}
                direction='row'
                alignItems='center'
              >
                <p className='route'>{`${t('From')}: ${from ? from.name : ''}`}</p>
                <EastIcon className='route-icons'/>
                <p className='route'>{`${t('To')}: ${pointSelect.name || ''}`}</p>
              </Stack>
            </Stack>
          </Stack>
          <FloorMap
            className="explore-floor-map-detail"
            floorImage={floorImage}
            doubleClickZoom={false}
            defaultDotSize={20}
            markers={path}
            center={centerMap}
            enabledPolyline
          />
          <Paper
            className='steps'
            elevation={3}
          >
            {
              showInstructions ?
              <Stack
                spacing={1}
                direction="column"
                alignItems="start"
                justifyContent="flex-start"
              >
                <IconButton
                  className="instructions-close-button"
                  onClick={() => setShowInstructions(false)} >
                <CloseIcon />
                </IconButton>
                <StepsMap
                  steps={path}
                  onClickPath={onClickPath}
                />
              </Stack>
              :
              <Button
                onClick={() => setShowInstructions(true)}
                variant='text'
              >
                <p>{t('View instructions')}</p>
              </Button>
            }
          </Paper>
        </DialogContent>
      </Dialog>
    )
  }, [centerMap, floorImage, from, getFloorName, getPlace, onClickPath, openDetailMap, path, pointSelect, showInstructions])

  const closeHowToGet = useCallback(() => {
    setOpenHowToFGet(false)
    setErrorFrom(false)
    setFrom(null)
    selectedMarker.selected = false
    setSelectedMarker(null)
    setCollapseControls(false)
  }, [setOpenHowToFGet, setErrorFrom, setFrom, selectedMarker, setSelectedMarker, setCollapseControls])

  const howToGet = useCallback(() => {
    return (
      <SwipeableDrawer
        className='how-to-get'
        anchor="bottom"
        open={openHowToFGet}
        onClose={closeHowToGet}
        onOpen={() => setOpenHowToFGet(true)}
      >
        <Stack
          direction="column"
          spacing={2}
        >
          <h1>
            ¿Cómo llegar?
            <IconButton
              className="close-button"
              aria-label="close"
              onClick={closeHowToGet} >
              <CloseIcon />
            </IconButton>
          </h1>
          <Divider />
          <h3>{getPlace()}</h3>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
            className="static-detail-map-filters"
          >
            <Autocomplete
              noOptionsText="Sin opciones"
              variant="outlined"
              value={from}
              onChange={(_, newValue) => setExploreFrom(newValue)}
              options={origins}
              sx={{ width: 235 }}
              renderInput={(params) =>
                <TextField
                  {...params}
                />
              }
            />
            <ArrowForwardIcon />
            <TextField
              variant="outlined"
              value={pointSelect.name}
            />
          </Stack>
          {
            errorFrom &&
            <p className="error">
              Esta ruta no está disponible aún, intenta ingresando otros puntos.
            </p>
          }
          <Stack
            spacing={3}
            direction="column"
            alignItems="stretch"
            justifyContent="center"
            className="how-to-get-buttons"
          >
            <Button
              disabled={!from}
              variant="contained"
              onClick={fetchPath}>
              Empezar
            </Button>
            <Button
              variant="outlined"
              onClick={closeHowToGet}>
              Cancelar
            </Button>
          </Stack>
        </Stack>
      </SwipeableDrawer>
    )
  }, [errorFrom, fetchPath, from, getPlace, openHowToFGet, origins, pointSelect.name, setExploreFrom, closeHowToGet])

  return (
    <div className="Explore">
      <Layout>
        <FloorMap
          className={`explore-floor-map ${fullScreen ? 'fullScreen' : ''}`}
          floorImage={floorImage}
          clickAction={clickAction}
          doubleClickZoom={false}
          markers={markers}
          closePopup={closePopup}
          center={centerGlobalMap}
          markerPhotoSrc={markerPhoto.src}
          onClickMarker={onClickMarker}
          renderControls={renderControls}
          selectedMarker={selectedMarker}
          setSelectedMarker={setSelectedMarker}
          setCollapseControls={setCollapseControls}
        />
      </Layout>
      {detailRoom()}
      {detailMap()}
      {exitAlert()}
      {howToGet()}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    manualFullScreen: state.explorer.manualFullScreen,
    defaultSite: state.explorer.site,
    defaultfloor: state.explorer.floor,
    defaultBuilding: state.explorer.building,
  }
}

export default connect(mapStateToProps)(Explore)