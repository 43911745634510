import React, { useCallback, useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import {
  MapContainer,
  Marker,
  Popup
} from 'react-leaflet'

import {
  Stack,
  Button,
  IconButton
} from '@mui/material'

import * as L from 'leaflet'
import './FloorMap.sass'
import { setLoading } from '../../../state/actions'

import {
  Close as CloseIcon,
  MeetingRoomRounded as MeetingRoomRoundedIcon
} from '@mui/icons-material'

const INITIAL_OFFSET = 4

const defaultFunction = () => { }
let polyline = null
let initialCenter = { x: 0, y: 0 }

function FloorMap(props) {
  const {
    className,
    markers = [],
    floorImage,
    defaultDotSize = 30,
    enabledPolyline = false,
    doubleClickZoom = true,
    classicPosition = false,
    center = initialCenter,
    markerPhotoSrc = '',
    onClickMarker = defaultFunction,
    onClickMap = defaultFunction,
    onDoubleClickMap = defaultFunction,
    clickAction = defaultFunction,
    closePopup = 0,
    renderControls,
    selectedMarker,
    setSelectedMarker = defaultFunction,
    setCollapseControls = defaultFunction
  } = props

  const ref = useRef()
  const [map, setMap] = useState(null)
  const [mapReady, setMapReady] = useState(false)
  const [image, setImage] = useState(null)

  const getPosition = useCallback(m => {
    if (classicPosition) return [m.y, m.x]

    const imageHeight = floorImage.size[1]
    return [
      - m.y + imageHeight - INITIAL_OFFSET,
      m.x + INITIAL_OFFSET
    ]
  }, [classicPosition, floorImage.size])

  useEffect(() => {
    if (!enabledPolyline) return
    if (!mapReady) return
    if (polyline) map.removeLayer(polyline)
    if (markers.length === 0) return
    const m = markers.map(m => getPosition(m))
    const p = L.polyline(m).addTo(map)
    p.setStyle({ color: '#F39C12', weight: 4 })
    polyline = p
  }, [enabledPolyline, getPosition, map, mapReady, markers])

  useEffect(() => {
    if (!ref.current) { ref.current = 1; return }
    if (!map) return
    if (!map.setView) return
    if (initialCenter === center) return
    initialCenter = center
    map.setView(getPosition(center))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center])

  useEffect(() => {
    if (!map) return
    if (!map.closePopup) return
    map.closePopup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closePopup])

  const whenReady = ({ target: map }) => {
    map.on('click', onClickMap)
    map.on('dblclick', onDoubleClickMap)
    setMap(map)
    setMapReady(true)
    if (floorImage.url) loadImage(floorImage, map)
  }

  const loadImage = (floorImage, map) => {
    const { url, size } = floorImage
    const _size = [...size]
    const bounds = [[0, 0], _size.reverse()]
    if (image) map.removeLayer(image)
    setImage(L.imageOverlay(url, bounds).addTo(map))
    map.fitBounds(bounds)
  }

  useEffect(() => {
    if (!map) return
    if (!floorImage.url) return
    loadImage(floorImage, map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorImage])

  const MarkerRoom = m => {
    return `
    <div class="room-marker">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 33 32">
        <circle cx="16.5" cy="16" r="15.5" stroke="#fff" />
        <path fill="#fff" d="M16.8 10a2.7 2.7 0 0 0-2.6 2.7 2.6 2.6 0 1 0 2.6-2.7Zm-6 1.3a2 2 0 0 0-2 2c0 1.1 1 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-2-2Zm12 0a2 2 0 0 0-2 2c0 1.1 1 2 2 2a2 2 0 0 0 2-2 2 2 0 0 0-2-2ZM9.8 16c-1.2 0-2.3 1-2.3 2.3v2.4h3.3v-1.4a4 4 0 0 1 1.7-3.2l-.7-.1h-2Zm12 0-.6.1a4 4 0 0 1 1.6 3.2v1.4h3.4v-2.4c-.1-1.2-1-2.3-2.4-2.3h-2Zm-7 .7a2.7 2.7 0 0 0-2.6 2.6V22h9.3v-2.7c0-1.4-1.2-2.6-2.7-2.6h-4Z" />
      </svg>
      <h1 title="${m.name}">${m.name}</h1>
    </div>
    `
  }

  const MarkerYellow = () => {
    return `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 22">
      <circle cx="10.5" cy="11.1" r="10.5" fill="#F39C12" transform="rotate(180 10.5 11)"/>
    </svg>
    `
  }

  const getRotation = useCallback(angle => {
    return `
      transform-origin: center center;
      transform: rotate(${angle}rad)
      scale(3)
      translateY(-1px);
    `
  }, [])

  const MarkerYellowArrow = useCallback((m, prevM) => {
    let theta = 0

    if (prevM) {
      const [x, y] = getPosition(m)
      const [x1, y1] = getPosition(prevM)

      const dx = x - x1
      const dy = y - y1
      theta = Math.atan2(dy, dx)
    }

    const style = getRotation(theta)

    return `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" style="${style}">
      <path fill="#F39C12" d="M18.8 20.5 11.2 28a1.7 1.7 0 1 0 2.3 2.4L20 24l6.5 6.5a1.7 1.7 0 1 0 2.3-2.4l-7.6-7.6c-.7-.7-1.7-.7-2.4 0Z"/>
    </svg>
    `
  }, [getPosition, getRotation])


  const getIcon = useCallback((m, prevM) => {
    let html = MarkerRoom(m)
    if (m.type === 'path' && m.end) html = MarkerYellowArrow(m, prevM)
    else if (m.type === 'path') html = MarkerYellow()

    return L.divIcon({
      html,
      iconSize: [defaultDotSize, defaultDotSize],
      iconAnchor: [defaultDotSize / 2, defaultDotSize / 2],
      className: `svg-icon ${m.active ? 'active' : m.selected ? 'selected' : ''}`
    })
  }, [MarkerYellowArrow, defaultDotSize])

  const clickActionMap = useCallback((action, m) => {
    map.closePopup()
    clickAction(action, m)
  }, [clickAction, map])

  const getMarkerPopup = useCallback(m => {
    if (!m.popup) return ''
    return (
      <Popup
        className="popup-marker"
        closeOnEscapeKey={false}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
        >
          <Stack
            direction="row"
            spacing={2}
          >
            <h2 className='header'>
              <MeetingRoomRoundedIcon />
              {m.name}
            </h2>
            <IconButton
              className="close-button"
              aria-label="close"
              onClick={() => {
                setCollapseControls(false)
                selectedMarker.selected = false
                setSelectedMarker(null)
                map.closePopup()
              }} >
              <CloseIcon />
            </IconButton>
          </Stack>
          {
            markerPhotoSrc &&
            <img
              className='popup-image'
              src={markerPhotoSrc}
              alt='place'
            />
          }
          <Button
            size="small"
            variant="contained"
            onClick={() => clickActionMap('howToGet', m)}
          >
            ¿Cómo llegar?
          </Button>
          {
            m.type === 'room' &&
            <Button
              size="small"
              variant="text"
              onClick={() => clickActionMap('detail', m)}>
              Más detalles
            </Button>
          }
        </Stack>
      </Popup>
    )
  }, [clickActionMap, map, markerPhotoSrc, selectedMarker, setSelectedMarker, setCollapseControls])

  const markerHandlersEvent = useCallback(marker => {
    return {
      click: e => onClickMarker(e, marker)
    }
  }, [onClickMarker])

  const getMarkers = useCallback(() => {
    return markers.map((m, idx) =>
      <Marker
        key={`marker-${idx}`}
        icon={getIcon(m, markers[idx - 1])}
        eventHandlers={markerHandlersEvent(m)}
        position={getPosition(m)}
      >
        {getMarkerPopup(m)}
      </Marker>
    )
  }, [getIcon, getMarkerPopup, getPosition, markerHandlersEvent, markers])

  return (
    <>
      <div className={`FloorMap ${className}`}>
        {floorImage.url &&
          <MapContainer
            closePopupOnClick={false}
            zoom={0}
            minZoom={-5}
            center={[0, 0]}
            crs={L.CRS.Simple}
            attributionControl={false}
            doubleClickZoom={doubleClickZoom}
            whenReady={whenReady}>
            {getMarkers()}
            {renderControls && renderControls()}
          </MapContainer>
        }
      </div>
    </>
  )
}

const mapDispatchToProps = {
  setLoading
}

export default connect(null, mapDispatchToProps)(FloorMap)
