import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  palette: {
    primary: {
      main: "#6C85D3"
    },
    error: {
      main: "#CF706F"
    }
  }
});

export default theme;