import * as React from 'react'
import { connect } from 'react-redux'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AppBar, Toolbar, IconButton, Button, MenuItem, Menu } from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import Logo from '../../../common/components/Svgs/Logo'

import {
  loadLogout
} from '../../../state/actions'

import './Header.sass'

function Header({ loadLogout }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleMenu = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleOption = option => {
    if (option === 'logout') loadLogout()
  }

  const StyledMenu = styled(props => (
    <Menu
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...props}
    />
  ))(() => ({
    '& .MuiList-root ': {
      padding: '0px'
    },
    '& .MuiPaper-root': {
      borderRadius: '0 0 4px 4px',
      minWidth: 229,
      color: 'black',
      background: '#F9F9F9',
      boxShadow: '0px 0px 6px rgba(146, 146, 146, 0.5)'
    },
    '& .MuiMenuItem-root:hover': {
      background: '#D8DFF5'
    }
  }));

  return (
    <AppBar position="static" className="header">
      <Toolbar className="header-toolbar">
        <Button
          variant="text"
          onClick={() => navigate('/')}>
          <Logo className='logo-img' />
        </Button>
        <div className='ml-auto'>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-app-bar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <MoreVertIcon />
          </IconButton>
          <StyledMenu
            id="menu-app-bar"
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleOption('logout')}>{t('logout')}</MenuItem>
          </StyledMenu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

const mapDispatchToProps = {
  loadLogout
}

export default connect(null, mapDispatchToProps)(Header)