const getCurrentBrowser = () => {
  var ua = navigator.userAgent
  var tem
  var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || []
    return 'IE ' + (tem[1] || '')
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
  return M.join(' ');
}


const stopEvent = e => {
  if (e.preventDefault !== undefined) e.preventDefault()
  if (e.stopPropagation !== undefined) e.stopPropagation()
}

const defaultFunction = () => { }

const capitalizeFirstLetter = (text) => {
  if (typeof text !== 'string') {
    return ''; 
  }

  if (text === text.toUpperCase()) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
  
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export {
  defaultFunction,
  getCurrentBrowser,
  stopEvent,
  capitalizeFirstLetter
}