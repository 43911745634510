import { Types } from '../actionTypes'

const initialState = {
  isOnline: null,
  goHome: 0,
  goLogin: 0,
  lng: 'es'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_GO_HOME:
      return {
        ...state,
        goHome: state.goHome + 1,
        goLogin: 0
      }
    case Types.SET_GO_LOGIN:
      return {
        ...state,
        goLogin: state.goLogin + 1,
        goHome: 0
      }
    case Types.SET_LANGUAGE:
      return {
        ...state,
        lng: action.payload
      }
    default:
      return state
  }
}

export default reducer