import axios from 'axios'
const DEFAULT_DOMAIN = 'public'

const clearDomain = domain => {
  const _domain = domain ||
    localStorage.getItem('domain') ||
    DEFAULT_DOMAIN

  if (
    _domain === 'undefined' ||
    _domain === DEFAULT_DOMAIN
  ) return ''
  return `${_domain}.`
}

const getBaseUrl = domain => {
  return process.env.REACT_APP_STAGE === 'production' ?
    `https://${clearDomain(domain)}corporateparking.parso.cr/api` :
    `https://${clearDomain(domain)}corporatedev.parso.cr/api`
}

const api = axios.create()

api.interceptors.request.use(
  config => {
    const sessionTokens = localStorage.getItem('token')
    if (sessionTokens) {
      config.headers['access-token'] = sessionTokens
    }
    config.baseURL = getBaseUrl()
    return config
  }
)

const setToken = token => {
  localStorage.setItem('token', token)
}

const clearToken = () => {
  localStorage.setItem('token', '')
  localStorage.setItem('domain', '')
}

const isAuth = () => {
  return JSON.parse(localStorage.getItem('token'))
}


export {
  api,
  getBaseUrl,
  setToken,
  clearToken,
  isAuth
}

