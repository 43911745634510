const ArrowUpperRight = ({ className = '', style = {}, width = 7, height = 30 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 13">
      <path fill="#323232" d="M12.8 9a.9.9 0 0 1-1-1V3.1l-9.5 9.6a.9.9 0 1 1-1.2-1.3L10.6 2H5.8a.9.9 0 0 1-1-.9c0-.4.5-.8 1-.8h7c.4 0 .8.4.8.8v7c0 .5-.4 1-.8 1Z" />
    </svg>
  )
}

export default ArrowUpperRight
