import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

function GlobalHandler({ children, goLogin }) {
  const ref = useRef()
  const navigate = useNavigate()

  useEffect(() => {
    if (!ref.current) {
      ref.current = 1
      return
    }


    if (goLogin) navigate('/login')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goLogin])



  return children
}

const mapStateToProps = state => {
  return {
    goLogin: state.utilities.goLogin
  }
}

export default connect(mapStateToProps)(GlobalHandler)