const ArrowDown = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.987578 11.2889C1.32883 10.9477 1.88008 10.9477 2.22133 11.2889L5.62508 14.6839L5.62508 1.41016C5.62508 0.928906 6.01883 0.535156 6.50008 0.535156C6.98133 0.535156 7.37508 0.928906 7.37508 1.41016L7.37508 14.6927L10.7701 11.2977C11.1113 10.9564 11.6626 10.9564 12.0038 11.2977C12.3451 11.6389 12.3451 12.1902 12.0038 12.5314L7.11258 17.4227C6.77133 17.7639 6.22008 17.7639 5.87883 17.4227L0.987578 12.5314C0.655078 12.1902 0.655078 11.6302 0.987578 11.2889Z" fill="#323232" />
    </svg>
  )
}

export default ArrowDown
