const Logo = ({ className = '', style = {} }) => {
  return (
    <svg className={className} style={style} width="284" height="38" viewBox="0 0 284 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M35.4456 5.41382C36.6471 5.43282 37.844 5.5671 39.0198 5.81483L39.7434 5.95431L39.6126 8.4214C38.1044 8.2732 36.9973 8.19474 36.265 8.19474C34.8092 8.19474 33.8415 8.51729 33.3446 9.1624C32.8477 9.8075 32.6385 11.0367 32.6385 12.8325C32.5285 14.1155 32.776 15.4039 33.3533 16.5549C33.8328 17.2436 34.8266 17.5923 36.3348 17.5923L39.691 17.357L39.7782 19.8502C38.3419 20.141 36.884 20.313 35.4194 20.3646C33.24 20.3646 31.7086 19.7834 30.8252 18.621C29.9418 17.4587 29.4856 15.5292 29.4565 12.8325C29.4565 10.1562 29.9186 8.25576 30.8601 7.12247C31.8016 5.98918 33.3185 5.41382 35.4456 5.41382Z" fill="#6C85D3" />
      <path d="M43.3981 7.27939C44.3629 6.03568 46.0076 5.41382 48.3323 5.41382C50.657 5.41382 52.2959 6.03568 53.249 7.27939C54.2254 8.51729 54.7223 10.3829 54.7223 12.8587C54.7223 15.3345 54.2428 17.2175 53.2926 18.4728C52.3424 19.7282 50.6773 20.3646 48.3323 20.3646C45.9872 20.3646 44.3309 19.7369 43.3807 18.4728C42.4304 17.2088 41.9423 15.3432 41.9423 12.8587C41.9423 10.3741 42.413 8.51729 43.3981 7.27939ZM45.717 16.5898C46.005 17.003 46.4018 17.3284 46.8634 17.53C47.325 17.7315 47.8334 17.8014 48.3323 17.7318C48.8311 17.8014 49.3395 17.7315 49.8011 17.53C50.2627 17.3284 50.6595 17.003 50.9476 16.5898C51.4645 15.4082 51.6887 14.1193 51.6014 12.8325C51.7031 11.5603 51.4623 10.284 50.904 9.13625C50.6083 8.74529 50.2151 8.43885 49.7638 8.24761C49.3125 8.05637 48.8188 7.98703 48.3323 8.04654C47.8513 7.99333 47.3648 8.06576 46.9201 8.25675C46.4755 8.44774 46.088 8.75077 45.7955 9.13625C45.2412 10.2853 45.0006 11.5605 45.098 12.8325C44.9997 14.1165 45.212 15.4052 45.717 16.5898Z" fill="#6C85D3" />
      <path d="M57.7891 20.0507V5.72765H60.8839V7.47118C62.3612 6.4732 64.0197 5.77426 65.7658 5.41382V8.53473C64.3203 8.80136 62.9084 9.22523 61.5551 9.79879L60.9275 10.0516V20.0507H57.7891Z" fill="#6C85D3" />
      <path d="M68.1291 26.2575V5.72749H71.2239V6.59926C72.3218 5.86576 73.6008 5.44947 74.9201 5.39622C75.7011 5.33109 76.4862 5.45561 77.2087 5.75918C77.9312 6.06274 78.5696 6.53637 79.0697 7.13975C79.9415 8.29048 80.3861 10.2345 80.3861 12.9544C80.3861 15.6743 79.8892 17.5922 78.8866 18.6993C77.8841 19.8065 76.2714 20.3557 73.9699 20.3557C73.0593 20.3644 72.1495 20.2973 71.25 20.1552V26.2575H68.1291ZM74.3709 8.23817C73.4405 8.24524 72.5222 8.45029 71.6772 8.83969L71.25 9.04019V17.4876C72.0801 17.6475 72.9241 17.7235 73.7694 17.7142C74.2763 17.7736 74.79 17.7022 75.2615 17.5069C75.733 17.3116 76.1467 16.9988 76.4631 16.5984C77.0589 15.4217 77.3183 14.1034 77.2129 12.7888C77.2129 9.72598 76.2655 8.19458 74.3709 8.19458V8.23817Z" fill="#6C85D3" />
      <path d="M84.2994 7.27939C85.2816 6.03568 86.9176 5.41382 89.2074 5.41382C91.4972 5.41382 93.1362 6.03568 94.1242 7.27939C95.1093 8.51729 95.5974 10.3829 95.5974 12.8587C95.5974 15.3345 95.118 17.2175 94.1677 18.4728C93.2175 19.7282 91.5525 20.3646 89.2074 20.3646C86.8624 20.3646 85.206 19.7369 84.2558 18.4728C83.3056 17.2088 82.8261 15.3432 82.8261 12.8587C82.8261 10.3741 83.3143 8.51729 84.2994 7.27939ZM86.6357 16.5898C86.9625 16.948 87.3603 17.2341 87.8039 17.4298C88.2475 17.6256 88.7269 17.7267 89.2118 17.7267C89.6966 17.7267 90.1761 17.6256 90.6197 17.4298C91.0632 17.2341 91.4611 16.948 91.7878 16.5898C92.3127 15.4105 92.5402 14.1203 92.4504 12.8325C92.5502 11.5594 92.3063 10.2829 91.7442 9.13625C91.4184 8.79197 91.0257 8.51776 90.5903 8.33036C90.1549 8.14295 89.6858 8.0463 89.2118 8.0463C88.7377 8.0463 88.2687 8.14295 87.8332 8.33036C87.3978 8.51776 87.0052 8.79197 86.6793 9.13625C86.1172 10.2829 85.8733 11.5594 85.9732 12.8325C85.8833 14.1203 86.1108 15.4105 86.6357 16.5898Z" fill="#6C85D3" />
      <path d="M98.6913 20.0508V5.72775H101.786V7.47127C103.262 6.48297 104.918 5.79295 106.659 5.44006V8.56097C105.213 8.82505 103.801 9.24901 102.449 9.82503L101.812 10.0778V20.0508H98.6913Z" fill="#6C85D3" />
      <path d="M119.632 10.1388V16.6683C119.619 17.0101 119.738 17.3437 119.963 17.6011C120.224 17.828 120.551 17.9654 120.896 17.9934L120.809 20.3646C119.453 20.4438 118.111 20.0629 116.999 19.2836C115.558 19.9686 113.983 20.3287 112.387 20.3384C109.54 20.3384 108.116 18.8215 108.116 15.7878C108.063 15.2087 108.14 14.6252 108.34 14.0794C108.541 13.5336 108.86 13.0392 109.275 12.632C110.321 11.9005 111.564 11.5024 112.841 11.49L116.537 11.1762V10.1388C116.567 9.85168 116.539 9.56141 116.453 9.28564C116.368 9.00987 116.227 8.75438 116.04 8.53473C115.623 8.20474 115.097 8.04301 114.567 8.08141C113.338 8.08141 111.812 8.15115 109.981 8.30807L109.066 8.36038L108.953 6.15482C110.829 5.68479 112.754 5.4361 114.689 5.41382C116.432 5.41382 117.696 5.78868 118.472 6.54711C118.897 7.03702 119.219 7.60762 119.418 8.22476C119.617 8.8419 119.69 9.4929 119.632 10.1388ZM113.215 13.6956C112.939 13.6863 112.663 13.7367 112.408 13.8435C112.152 13.9504 111.923 14.111 111.735 14.3145C111.547 14.5181 111.405 14.7597 111.319 15.023C111.233 15.2862 111.205 15.5649 111.237 15.8401C111.237 17.1565 111.821 17.819 112.98 17.819C114.008 17.806 115.029 17.6505 116.014 17.357L116.528 17.1913V13.3817L113.215 13.6956Z" fill="#6C85D3" />
      <path d="M131.261 8.39495H127.312V14.6978C127.264 15.4795 127.35 16.2636 127.565 17.0167C127.739 17.4003 128.184 17.592 128.89 17.592L131.235 17.5049L131.375 19.9981C130.414 20.2051 129.436 20.3277 128.454 20.3643C126.833 20.3643 125.717 19.9981 125.115 19.2484C124.514 18.4987 124.244 17.1039 124.244 15.0378V8.39495H122.43V5.72735H124.261V1.57776H127.356V5.72735H131.305L131.261 8.39495Z" fill="#6C85D3" />
      <path d="M144.007 17.4703L144.818 17.3918L144.87 19.7107C142.967 20.1166 141.028 20.3355 139.082 20.3646C136.963 20.3646 135.438 19.7892 134.514 18.621C133.59 17.4529 133.128 15.596 133.128 13.033C133.128 7.95355 135.2 5.41382 139.343 5.41382C143.353 5.41382 145.359 7.60194 145.359 11.9782L145.158 14.2099H136.275C136.212 15.1464 136.487 16.0743 137.051 16.8252C137.434 17.1446 137.878 17.3827 138.356 17.525C138.834 17.6674 139.336 17.7111 139.832 17.6534C141.183 17.6447 142.578 17.5923 144.007 17.4703ZM142.264 11.8038C142.373 10.7656 142.143 9.71988 141.61 8.82241C141.324 8.51074 140.97 8.26974 140.575 8.11845C140.18 7.96716 139.756 7.90972 139.335 7.95065C138.905 7.90943 138.471 7.96649 138.066 8.11751C137.661 8.26854 137.296 8.50957 136.998 8.82241C136.431 9.68603 136.165 10.7124 136.24 11.7428L142.264 11.8038Z" fill="#6C85D3" />
      <path d="M164.867 17.4703L165.669 17.3918L165.722 19.7107C163.821 20.1165 161.885 20.3355 159.942 20.3646C157.815 20.3646 156.298 19.7892 155.374 18.621C154.45 17.4529 153.979 15.596 153.979 13.033C153.979 7.95355 156.051 5.41382 160.195 5.41382C164.205 5.41382 166.21 7.60194 166.21 11.9782L166.018 14.2099H157.135C157.07 15.1453 157.342 16.0731 157.902 16.8252C158.285 17.1441 158.73 17.382 159.208 17.5243C159.686 17.6667 160.188 17.7106 160.683 17.6534C162.043 17.6447 163.403 17.5923 164.867 17.4703ZM163.124 11.8038C163.222 10.7684 162.983 9.72908 162.444 8.83985C162.158 8.52817 161.804 8.28718 161.409 8.13589C161.014 7.9846 160.59 7.92715 160.169 7.96808C159.739 7.92687 159.305 7.98393 158.9 8.13495C158.495 8.28597 158.13 8.52701 157.832 8.83985C157.269 9.70505 157.003 10.7302 157.074 11.7603L163.124 11.8038Z" fill="#6C85D3" />
      <path d="M167.85 5.72754H171.171L173.978 10.6879L176.811 5.72754H180.133L175.87 12.7801L180.133 20.0506H176.811L173.978 15.1513L171.171 20.0506H167.85L171.999 12.8586L167.85 5.72754Z" fill="#6C85D3" />
      <path d="M182.513 26.2573V5.72723H185.608V6.599C186.706 5.8655 187.985 5.44921 189.304 5.39596C190.086 5.33009 190.871 5.45427 191.593 5.75789C192.316 6.06151 192.954 6.53554 193.454 7.13949C194.326 8.29022 194.77 10.2342 194.77 12.9541C194.77 15.6741 194.274 17.5919 193.271 18.6991C192.268 19.8062 190.656 20.3554 188.354 20.3554C187.444 20.3635 186.534 20.2964 185.634 20.1549V26.2573H182.513ZM188.755 8.23791C187.829 8.24074 186.914 8.43981 186.07 8.82199L185.643 9.0225V17.4699C186.473 17.6298 187.317 17.7058 188.162 17.6965C188.669 17.7559 189.183 17.6845 189.655 17.4892C190.126 17.2939 190.54 16.9811 190.856 16.5807C191.452 15.404 191.711 14.0857 191.606 12.7711C191.6 9.71991 190.65 8.19432 188.755 8.19432V8.23791Z" fill="#6C85D3" />
      <path d="M208.09 17.4703L208.892 17.3918L208.953 19.7107C207.049 20.1166 205.111 20.3355 203.165 20.3646C201.046 20.3646 199.521 19.7892 198.597 18.621C197.672 17.4529 197.21 15.596 197.21 13.033C197.21 7.95355 199.282 5.41382 203.426 5.41382C207.436 5.41382 209.441 7.60194 209.441 11.9782L209.241 14.2099H200.358C200.295 15.1464 200.57 16.0743 201.133 16.8252C201.517 17.1446 201.961 17.3827 202.439 17.525C202.917 17.6674 203.419 17.7111 203.914 17.6534C205.248 17.6447 206.66 17.5923 208.09 17.4703ZM206.347 11.8038C206.442 10.7697 206.207 9.73194 205.675 8.83985C205.389 8.52871 205.035 8.28809 204.64 8.13684C204.245 7.9856 203.821 7.92783 203.4 7.96808C202.97 7.92687 202.536 7.98393 202.131 8.13495C201.727 8.28597 201.362 8.52701 201.064 8.83985C200.497 9.70347 200.23 10.7299 200.305 11.7603L206.347 11.8038Z" fill="#6C85D3" />
      <path d="M212.449 20.0508V5.72775H215.544V7.47127C217.018 6.4846 218.67 5.79467 220.408 5.44006V8.56097C218.963 8.82761 217.551 9.25148 216.198 9.82503L215.57 10.0778V20.0508H212.449Z" fill="#6C85D3" />
      <path d="M222.789 3.29526V0H225.91V3.29526H222.789ZM222.789 20.0506V5.72748H225.91V20.0506H222.789Z" fill="#6C85D3" />
      <path d="M239.892 17.4703L240.694 17.3918L240.755 19.7107C238.852 20.1166 236.913 20.3355 234.967 20.3646C232.848 20.3646 231.323 19.7892 230.399 18.621C229.475 17.4529 229.004 15.596 229.004 13.033C229.004 7.95355 231.076 5.41382 235.219 5.41382C239.23 5.41382 241.235 7.60194 241.235 11.9782L241.034 14.2099H232.16C232.094 15.1453 232.367 16.0731 232.927 16.8252C233.31 17.1441 233.754 17.382 234.232 17.5243C234.71 17.6667 235.212 17.7106 235.708 17.6534C237.068 17.6447 238.462 17.5923 239.892 17.4703ZM238.149 11.8038C238.256 10.7667 238.03 9.72247 237.503 8.82241C237.216 8.51112 236.86 8.27049 236.463 8.11929C236.067 7.96808 235.642 7.91037 235.219 7.95065C234.791 7.90949 234.358 7.96661 233.955 8.11767C233.552 8.26872 233.188 8.50975 232.892 8.82241C232.325 9.68603 232.058 10.7124 232.133 11.7428L238.149 11.8038Z" fill="#6C85D3" />
      <path d="M247.371 20.0501H244.25V5.72702H247.337V6.59879C248.528 5.85785 249.892 5.44298 251.294 5.39575C253.206 5.39575 254.508 5.93625 255.2 7.01723C255.897 8.0895 256.246 9.87661 256.246 12.3524V20.0327H253.195V12.457C253.267 11.3357 253.098 10.2119 252.698 9.16177C252.481 8.80788 252.164 8.52672 251.787 8.35432C251.409 8.18192 250.989 8.12614 250.579 8.19411C249.614 8.19204 248.659 8.38786 247.772 8.76948L247.345 8.93511L247.371 20.0501Z" fill="#6C85D3" />
      <path d="M265.242 5.41382C266.447 5.43322 267.646 5.5675 268.825 5.81483L269.54 5.95431L269.427 8.4214C267.919 8.2732 266.811 8.19474 266.07 8.19474C264.623 8.19474 263.647 8.51729 263.15 9.1624C262.653 9.8075 262.409 11.0367 262.409 12.8325C262.296 14.1157 262.544 15.405 263.124 16.5549C263.603 17.2436 264.597 17.5923 266.105 17.5923L269.453 17.357L269.54 19.8502C268.104 20.1413 266.646 20.3134 265.181 20.3646C263.002 20.3646 261.468 19.7834 260.578 18.621C259.689 17.4587 259.262 15.5292 259.297 12.8325C259.297 10.1533 259.765 8.24995 260.7 7.12247C261.636 5.99499 263.15 5.42544 265.242 5.41382Z" fill="#6C85D3" />
      <path d="M282.608 17.4703L283.41 17.3918L283.462 19.7107C281.559 20.1166 279.62 20.3355 277.674 20.3646C275.555 20.3646 274.03 19.7892 273.106 18.621C272.182 17.4529 271.719 15.596 271.719 13.033C271.719 7.95355 273.791 5.41382 277.935 5.41382C281.945 5.41382 283.95 7.60194 283.95 11.9782L283.75 14.2099H274.867C274.808 15.146 275.083 16.0726 275.642 16.8252C276.026 17.1446 276.47 17.3827 276.948 17.525C277.426 17.6674 277.928 17.7111 278.423 17.6534C279.775 17.6447 281.169 17.5923 282.608 17.4703ZM280.864 11.8038C280.976 10.7665 280.749 9.72089 280.219 8.82241C279.933 8.51074 279.579 8.26974 279.184 8.11845C278.789 7.96716 278.365 7.90972 277.944 7.95065C277.514 7.90943 277.08 7.96649 276.675 8.11751C276.271 8.26854 275.905 8.50957 275.608 8.82241C275.041 9.68603 274.774 10.7124 274.849 11.7428L280.864 11.8038Z" fill="#6C85D3" />
      <path d="M10.0789 22.5958L13.8188 21.2795L17.9597 8.82197C17.0166 7.6378 15.8187 6.68151 14.4551 6.02424C13.0914 5.36697 11.5971 5.02563 10.0833 5.02563C8.56949 5.02563 7.07518 5.36697 5.71152 6.02424C4.34786 6.68151 3.14995 7.6378 2.20691 8.82197L6.34778 21.2533L10.0789 22.5958ZM10.0789 10.086C10.8293 10.0843 11.5633 10.3052 12.1881 10.7209C12.8129 11.1365 13.3003 11.7282 13.5886 12.4209C13.877 13.1137 13.9533 13.8765 13.808 14.6126C13.6627 15.3488 13.3022 16.0253 12.7722 16.5566C12.2422 17.0878 11.5665 17.4498 10.8307 17.5968C10.0948 17.7439 9.3319 17.6693 8.63847 17.3825C7.94503 17.0958 7.35226 16.6097 6.93518 15.9859C6.51811 15.3621 6.29548 14.6286 6.29548 13.8782C6.29547 12.874 6.69381 11.9107 7.40309 11.1998C8.11238 10.4889 9.07469 10.0883 10.0789 10.086Z" fill="#6C85D3" />
      <path d="M2.04005 9.04858C0.706311 10.8024 -0.0108079 12.9476 0.000123138 15.1509C0.0451086 16.5915 0.340084 18.0133 0.871886 19.3528L6.10246 21.2097L2.04005 9.04858Z" fill="#6C85D3" />
      <path d="M6.1812 21.4455L0.950623 19.5886C1.68732 21.4235 2.6135 23.1766 3.71411 24.8192L6.94835 23.6772L6.1812 21.4455Z" fill="#6C85D3" />
      <path d="M19.3191 19.3268C19.8509 17.9873 20.1459 16.5655 20.1909 15.1249C20.1954 12.9212 19.4756 10.7771 18.1422 9.02258L14.0711 21.1837L19.3191 19.3268Z" fill="#6C85D3" />
      <path d="M3.87109 25.037C5.6817 27.7425 7.72346 30.286 9.97343 32.6388L7.06175 23.9211L3.87109 25.037Z" fill="#6C85D3" />
      <path d="M13.0245 23.6425L13.7306 21.5328L10.4005 22.7097L13.0245 23.6425Z" fill="#6C85D3" />
      <path d="M7.13966 23.6425L9.76367 22.7097L6.43353 21.5328L7.13966 23.6425Z" fill="#6C85D3" />
      <path d="M7.20135 23.8426L10.0782 32.473L12.955 23.8426L10.0782 22.8226L7.20135 23.8426Z" fill="#6C85D3" />
      <path d="M10.2517 32.6389C12.5 30.2878 14.5417 27.7474 16.3541 25.0459L13.1721 23.9126L10.2517 32.6389Z" fill="#6C85D3" />
      <path d="M13.2245 23.7114L16.4588 24.8534C17.5594 23.2107 18.4856 21.4577 19.2223 19.6228L13.9917 21.4797L13.2245 23.7114Z" fill="#6C85D3" />
      <path d="M33.511 34.4254H31.6628V37.2674H30.1808V28.1226H33.511C35.5858 28.1226 36.6232 29.1454 36.6232 31.1912C36.6834 32.0631 36.4056 32.9247 35.8473 33.5972C35.5366 33.8897 35.1684 34.1144 34.7662 34.257C34.364 34.3995 33.9365 34.4569 33.511 34.4254ZM31.6628 33.1439H33.511C34.5745 33.1439 35.1063 32.4901 35.1063 31.1912C35.1502 30.7061 35.0136 30.2219 34.7227 29.8312C34.563 29.6776 34.3722 29.56 34.1631 29.4863C33.9541 29.4126 33.7317 29.3846 33.511 29.4041H31.6628V33.1439Z" fill="#6C85D3" />
      <path d="M36.9995 37.2674L39.2574 28.1226H42.2039L44.4792 37.2674H42.9449L42.448 35.3059H38.961L38.4641 37.2674H36.9995ZM40.3994 29.3605L39.2661 33.9982H42.1952L41.0706 29.3605H40.3994Z" fill="#6C85D3" />
      <path d="M47.2325 34.007V37.2674H45.7156V28.1226H49.2027C51.2717 28.1226 52.3033 29.0931 52.2975 31.0342C52.3487 31.5706 52.2329 32.1098 51.9661 32.5779C51.6993 33.046 51.2944 33.4203 50.8067 33.6495L52.3149 37.2412H50.7021L49.377 34.007H47.2325ZM50.798 31.043C50.8323 30.8237 50.8151 30.5995 50.7478 30.3881C50.6804 30.1766 50.5648 29.9838 50.41 29.8248C50.2552 29.6658 50.0655 29.545 49.856 29.4719C49.6464 29.3989 49.4228 29.3757 49.2027 29.4041H47.2325V32.7255H49.2463C49.464 32.7443 49.6831 32.7132 49.887 32.6347C50.091 32.5561 50.2743 32.4321 50.4232 32.2722C50.6845 31.9171 50.8167 31.4834 50.798 31.043Z" fill="#6C85D3" />
      <path d="M56.6817 29.274C55.5484 29.274 54.9905 29.6663 54.9905 30.4596C54.9739 30.6274 55.0024 30.7966 55.0728 30.9498C55.1433 31.103 55.2533 31.2347 55.3915 31.3314C55.9432 31.6189 56.529 31.8357 57.135 31.9765C57.8343 32.1192 58.4927 32.4171 59.0616 32.8482C59.2773 33.0723 59.4427 33.3399 59.5464 33.6331C59.6502 33.9263 59.6899 34.2383 59.6631 34.5482C59.6897 34.9522 59.6251 35.357 59.4743 35.7327C59.3234 36.1085 59.09 36.4455 58.7913 36.7189C58.1587 37.2261 57.3604 37.4808 56.5509 37.4337C55.7072 37.4253 54.8664 37.3348 54.0402 37.1635L53.5608 37.0763L53.7438 35.8471C54.6559 35.9871 55.5761 36.0686 56.4986 36.0912C57.597 36.0912 58.155 35.603 58.155 34.6179C58.1693 34.453 58.1426 34.287 58.0772 34.1349C58.0118 33.9828 57.9097 33.8492 57.7801 33.7462C57.2491 33.489 56.6816 33.3155 56.0976 33.2318C55.3871 33.0992 54.718 32.8005 54.1448 32.3601C53.9158 32.1205 53.7405 31.8349 53.6308 31.5222C53.521 31.2095 53.4793 30.8769 53.5085 30.5468C53.4837 30.1919 53.5435 29.8361 53.6829 29.5088C53.8223 29.1814 54.0374 28.8918 54.3105 28.6638C54.9623 28.1988 55.751 27.9656 56.5509 28.0012C57.3667 28.0139 58.1801 28.0926 58.9831 28.2366L59.4452 28.3151L59.3231 29.5181C58.0794 29.3554 57.1989 29.274 56.6817 29.274Z" fill="#6C85D3" />
      <path d="M67.509 36.2915C67.1529 36.6982 66.7037 37.0128 66.1998 37.2086C65.6959 37.4044 65.1521 37.4755 64.6148 37.416C64.0775 37.4748 63.534 37.4033 63.0302 37.2075C62.5264 37.0118 62.0772 36.6976 61.7205 36.2915C61.0744 35.2256 60.7702 33.9874 60.8488 32.7434C60.769 31.4855 61.073 30.2331 61.7205 29.1517C62.0664 28.7427 62.5067 28.4241 63.0035 28.2236C63.5002 28.0231 64.0383 27.9467 64.5712 28.001C65.1112 27.9428 65.6572 28.0191 66.1606 28.2229C66.664 28.4267 67.1093 28.7517 67.4567 29.1692C68.1019 30.2547 68.4057 31.5091 68.3285 32.7695C68.4219 33.9997 68.1359 35.2289 67.509 36.2915ZM62.88 35.3325C63.0984 35.5795 63.3668 35.7772 63.6674 35.9126C63.9679 36.0481 64.2938 36.1181 64.6235 36.1181C64.9532 36.1181 65.2791 36.0481 65.5797 35.9126C65.8803 35.7772 66.1486 35.5795 66.367 35.3325C66.7237 34.5093 66.8762 33.6121 66.8116 32.7172C66.865 31.8213 66.7067 30.9254 66.3496 30.102C66.1438 29.844 65.8826 29.6357 65.5853 29.4925C65.288 29.3494 64.9622 29.2751 64.6322 29.2751C64.3022 29.2751 63.9765 29.3494 63.6792 29.4925C63.3819 29.6357 63.1206 29.844 62.9149 30.102C62.5486 30.9228 62.3871 31.8202 62.4441 32.7172C62.3634 33.6078 62.4979 34.5048 62.8364 35.3325H62.88Z" fill="#6C85D3" />
    </svg>
  )
}

export default Logo