import * as React from 'react'
import { connect } from 'react-redux'
import { Backdrop, CircularProgress } from '@mui/material'

function GlobalBackdrop({ loading }) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 100000 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

const mapStateToProps = state => {
  return {
    loading: state.backdrop.loading
  }
}

export default connect(mapStateToProps)(GlobalBackdrop)
