const AirCondition = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 14">
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M6.5.58v12.84M8.14 1.96a3.14 3.14 0 0 1-3.28 0m0 10.08a3.15 3.15 0 0 1 3.28 0M12 3.8 1 10.21m10.64-4.3A3.2 3.2 0 0 1 10 3.05M1.36 8.09A3.2 3.2 0 0 1 3 10.95M1 3.8l11 6.42M1.36 5.9A3.2 3.2 0 0 0 3 3.05m8.64 5.04A3.2 3.2 0 0 0 10 10.95" />
    </svg>
  )
}

export default AirCondition