const ArrowUpperLeft = ({ className = '', style = {}, width = 7, height = 30 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 13">
      <path fill="#323232" d="M9.1 1c0 .5-.4 1-.8 1h-5l9.6 9.4a.9.9 0 1 1-1.2 1.3L2 3v5c0 .4-.4.8-.8.8a.9.9 0 0 1-1-.9V1c0-.4.5-.8 1-.8h7c.4 0 .8.4.8.8Z" />
    </svg>
  )
}

export default ArrowUpperLeft
