const ArrowDownLeft = ({ className = '', style = {}, width = 7, height = 30 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
      <path fill="#323232" d="M1.3 4.7c.4 0 .8.4.8.8v5L11.6.8A.9.9 0 1 1 13 2.1l-9.5 9.6h4.9c.4 0 .8.4.8.8 0 .5-.4 1-.8 1h-7a.9.9 0 0 1-1-1v-7c0-.4.5-.8 1-.8Z" />
    </svg>
  )
}

export default ArrowDownLeft
