const Marker = ({ className = '', style = {}, width = 7 }) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 27 38">
      <path fill="#000" fillOpacity=".9" fillRule="evenodd" d="M13.3 0a13 13 0 0 0-13 13c0 9.8 13 24.2 13 24.2s13-14.4 13-24.1a13 13 0 0 0-13-13Zm0 17.7a4.6 4.6 0 1 1 0-9.3 4.6 4.6 0 0 1 0 9.3Z" clipRule="evenodd" />
    </svg>
  )
}

export default Marker